<template>
  <div>
    <Card title="Countries" :loading="loading">
      <v-row>
        <v-col v-for="country in countries" :key="country._id" cols="12" md="4">
          <h3 class="capitalize">
            {{ country.name }}
            <v-icon
              color="secondary"
              size="20px"
              class="ml-2"
              @click="updateLocation('country', country)"
              >mdi-pencil</v-icon
            >
            <v-icon
              color="error"
              size="20px"
              class="ml-1"
              @click="makeDeleteLocation('country', country)"
            >
              mdi-delete
            </v-icon>
          </h3>

          <ul>
            <li
              v-for="city in country.cities"
              :key="city._id"
              class="capitalize"
            >
              {{ city.name }}
              <v-icon
                color="secondary"
                size="15px"
                class="ml-2"
                @click="updateLocation('city', city)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                color="error"
                size="15px"
                class="ml-1"
                @click="makeDeleteLocation('city', city)"
              >
                mdi-delete
              </v-icon>
            </li>
          </ul>
        </v-col>
      </v-row>
    </Card>

    <v-btn
      dark
      fixed
      fab
      color="secondary"
      style="bottom: 5rem; right: 3rem"
      @click="openDialog = true"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <v-dialog v-model="openDialog" max-width="500px">
      <v-card class="pa-2 pt-4">
        <v-card-text>
          <h3>Add Country</h3>
          <div class="d-flex align-items-center">
            <v-text-field
              label="Add a new Country"
              v-model="country"
              class="mx-2"
              hide-details
            ></v-text-field>
            <v-btn
              color="secondary"
              @click="addNewCountry"
              :loading="countryLoading"
              >Add Country</v-btn
            >
          </div>

          <v-divider class="my-4"></v-divider>

          <h3>Add New Country</h3>
          <v-autocomplete
            label="Select Country"
            v-model="country_id"
            :items="countries"
            cache-items
            required
            :item-text="'name'"
            :item-value="'_id'"
            class="pr-2"
          ></v-autocomplete>
          <div class="d-flex align-items-center">
            <v-text-field
              label="Add a new City"
              v-model="city"
              class="mx-2"
              hide-details
            ></v-text-field>
            <v-btn color="secondary" @click="addNewCity" :loading="cityLoading"
              >Add City</v-btn
            >
          </div>
        </v-card-text>
        <v-card-actions class="text-right"> </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editMode" max-width="500px" persistent>
      <v-card class="pa-2 pt-4">
        <v-card-text>
          <h3 class="mb-3">Update {{ updateKey }}</h3>
          <div class="d-flex align-items-center">
            <v-text-field
              :label="`Update ${updateKey}`"
              v-model="updateText"
              class="mx-2"
              hide-details
            ></v-text-field>
            <v-btn
              color="secondary"
              @click="updateNewLocation"
              :loading="countryLoading"
              >Update {{ updateKey }}</v-btn
            >
          </div>
        </v-card-text>
        <v-card-actions class="text-right">
          <v-btn color="error" @click="closePopup">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmation" max-width="500px" persistent>
      <v-card class="pa-2 pt-4">
        <v-card-text>
          <h3 class="mb-3">
            Are you sure you want to delete {{ updateKey }}??
          </h3>
        </v-card-text>
        <v-card-actions class="text-right">
          <v-btn color="error" @click="closePopup">Cancel</v-btn>
          <v-btn
            color="secondary"
            @click="deleteLocation"
            :loading="countryLoading"
            :disabled="countryLoading"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "system-admin-locations",
  data() {
    return {
      loading: false,
      editMode: false,
      openDialog: false,
      countryLoading: false,
      cityLoading: false,
      countries: [],
      country_id: "",
      country: "",
      city: "",
      // -----
      updateText: "",
      updateKey: "",
      updateObj: {},
      // confirmation
      confirmation: false,
    };
  },
  methods: {
    async addNewCountry() {
      if (!this.country) return;
      try {
        this.countryLoading = true;
        const resp = await this.axios.post("countries", {
          name: this.country,
        });
        this.countries.push({
          ...resp.data,
          cities: [],
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.countryLoading = false;
        this.country = "";
        this.openDialog = false;
      }
    },
    async addNewCity() {
      if (!this.city || !this.country_id) return;
      try {
        this.cityLoading = true;
        const resp = await this.axios.post("cities", {
          name: this.city,
          country_id: this.country_id,
        });
        const city = resp.data;
        const index = this.countries.findIndex(
          (c) => c._id === this.country_id
        );
        if (index !== -1) this.countries[index].cities.push(city);
      } catch (error) {
        console.log(error);
      } finally {
        this.country = "";
        this.city = "";
        this.country_id = "";
        this.cityLoading = false;
        this.openDialog = false;
      }
    },
    async fetchAllCountries() {
      try {
        this.loading = true;
        const resp = await this.axios.get("countries");
        const countries = resp.data.data;
        for (let i = 0; i < countries.length; i++) {
          const { _id } = countries[i];
          const resp2 = await this.axios.get(`cities?country_id=${_id}`);
          const cities = resp2.data.data;
          countries[i].cities = cities.sort(function (a, b) {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
        }
        this.countries = countries;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async updateNewLocation() {
      try {
        this.countryLoading = true;
        if (this.updateKey === "country") {
          await this.axios.put(`/countries/${this.updateObj._id}`, {
            name: this.updateText,
          });
        } else {
          await this.axios.put(`/cities/${this.updateObj._id}`, {
            name: this.updateText,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.closePopup();
        this.fetchAllCountries();
      }
    },
    async deleteLocation() {
      try {
        this.countryLoading = true;
        if (this.updateKey === "country") {
          await this.axios.delete(`/countries/${this.updateObj._id}`);
        } else {
          await this.axios.delete(`/cities/${this.updateObj._id}`);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.closePopup();
        this.fetchAllCountries();
      }
    },
    makeDeleteLocation(key, obj) {
      this.confirmation = true;
      this.updateKey = key;
      this.updateObj = obj;
    },
    updateLocation(key, obj) {
      this.editMode = true;
      this.updateText = obj.name;
      this.updateKey = key;
      this.updateObj = obj;
    },
    closePopup() {
      this.countryLoading = false;
      this.confirmation = false;
      this.editMode = false;
      this.updateText = "";
      this.updateKey = "";
      this.updateObj = {};
    },
  },
  created() {
    this.fetchAllCountries();
  },
};
</script>

<style lang="scss" scoped>
h3 {
  color: #333;
}
</style>
